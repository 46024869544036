"use client";
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState
} from "react";

type StepsProviderProps = {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
};

type StepsHighLightBarsProps = {
  count: number;
  canGoBack?: boolean;
};

const InitialValues: StepsProviderProps = {
  currentStep: 1,
  setCurrentStep: () => undefined
};

const StepsProviderContext = createContext(InitialValues);

export const useStepper = () => {
  const context = useContext(StepsProviderContext);

  if (!context) {
    throw new Error("Use step in a StepsProvider");
  }
  return context;
};

export default function StepsProvider({ children }: PropsWithChildren<{}>) {
  const [currentStep, setCurrentStep] = useState<number>(
    InitialValues.currentStep
  );
  const defaultValues = {
    currentStep,
    setCurrentStep
  };
  return (
    <StepsProviderContext.Provider value={defaultValues}>
      {children}
    </StepsProviderContext.Provider>
  );
}

export function StepsHighLightBars(props: StepsHighLightBarsProps) {
  const { count } = props;
  const { currentStep, setCurrentStep } = useStepper();

  return (
    <div className="flex gap-3">
      {Array.from({ length: count }, (_, i) => (
        <button
          type="button"
          // onClick={() => setCurrentStep(i + 1)}
          key={i}
          className={`h-2 flex-1 rounded-full
          ${currentStep === i + 1 ? "bg-danger" : "bg-light"}`}
        ></button>
      ))}
    </div>
  );
}
