import React, { useEffect, useState } from "react";
import { useStepper } from "../../context/StepsProvider";
import { CFormInput } from "@coreui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { colors } from "../../config/theme";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { useToast } from "../../hooks/useToast";
import { general } from "../../locales/general";
import { useNavigate, useParams } from "react-router-dom";
import { useCookie } from "../../hooks/useCookie";
import { useMutation, useQuery } from "react-query";
import apiService from "../../service/apiService";
import { apiConfig } from "../../config/apiConfig";

const filesSchema = yup.object().shape({
  type: yup.string().required(),
  file: yup.mixed().required()
});

const schema = yup.object().shape({
  files: yup
    .array()
    .of(filesSchema)
    .required()
});

type SavePayload = {
  files: {
    type: string;
    file: any;
  }[];
};

const vehicleDocs = [
  {
    name: "frontPicture",
    label: "Photo d'un taxi vu de face"
  },
  {
    name: "sidePicture",
    label: "Photo d'un taxi de côté"
  },
  {
    name: "pictureCarteGriseRecto",
    label: "Carte Grise Recto"
  },
  {
    name: "pictureCarteGriseVerso",
    label: "Carte Grise Verso"
  }
];

const driverDocs = [
  {
    name: "pictureCarteCinRecto",
    label: "Carte Identité National Recto"
  },
  {
    name: "pictureCarteCinVerso",
    label: "Carte Identité National Verso"
  },
  {
    name: "picturePermisRecto",
    label: "Permis de conduire Recto"
  },
  {
    name: "picturePermisVerso",
    label: "Permis de conduire Verso"
  },
  {
    name: "picturePermisConfianceRecto",
    label: "Permis de Confiance Recto"
  },
  {
    name: "picturePermisConfianceVerso",
    label: "Permis de Confiance Verso"
  },
  {
    name: "pictureCarteConducteurProRecto",
    label: "Carte de Conducteur Professionnel Recto"
  },
  {
    name: "pictureCarteConducteurProVerso",
    label: "Carte de Conducteur Professionnel Verso"
  }
];

export default function UploadDocument() {
  const { currentStep, setCurrentStep } = useStepper();
  const [documentType, setDocumentType] = useState("");
  const [files, setFiles] = useState<FileList | null>();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  });

  const {
    fields: fileFields,
    append: appendFileItemField,
    remove: removeFileItemField,
    update: updateFileItemField
  } = useFieldArray({
    control: control,
    name: "files"
  });

  const { toastError, toastSuccess } = useToast();
  useEffect(() => {
    if (documentType && fileFields.length) {
      fileFields.forEach((f, i) =>
        updateFileItemField(i, { type: "", file: f.file })
      );
    }
  }, [documentType]);

  const params = useParams();
  const { token } = useCookie("vToken");
  const driverId = params?.driverId!;

  const { data } = useQuery<Driver>(["getDriver"], () => {
    return apiService.MakeGetRequest(`users/driver/${driverId}`, token);
  });

  const uploadFileMutation = useMutation({
    mutationFn: (data: any) =>
      apiService.MakePutRequest(
        apiConfig.users.drivers.upload_docs(driverId),
        data,
        token,
        true
      ),
    mutationKey: [token, driverId, "upload-docs"]
  });
  const navigate = useNavigate();

  const onFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      Array.from(e.target.files).forEach(file =>
        appendFileItemField({ type: "", file })
      );
    }
    setFiles(e.target.files);
  };

  const documents =
    documentType === "driver-doc"
      ? driverDocs
      : documentType === "vehicle-doc"
      ? vehicleDocs
      : [];

  const onDocumentSave = async (data: SavePayload) => {
    try {
      const uploadRequest = data.files.map(file =>
        uploadFileMutation.mutateAsync({ type: file.type, document: file.file })
      );
      await Promise.all(uploadRequest);
      toastSuccess("success");
      navigate(`/drivers/${driverId}`);
    } catch (error) {
      toastError(general.fr.message.operationFailed);
    }
  };

  return (
    <div>
      <h5 className="fw-semibold mb-4">
        Télécharger des documents {data?.id ? `(${data?.fullName})` : ""}{" "}
      </h5>
      <div className="bg-white p-4">
        <div>
          <label htmlFor="doc-type" className="mb-1">
            Type de document:
          </label>
          <select id="doc-type" onChange={e => setDocumentType(e.target.value)}>
            <option value="">Type de document</option>
            <option value="driver-doc">Documents de conducteur</option>
            <option value="vehicle-doc">Documents de véhicule</option>
          </select>

          {documentType && (
            <div className="mt-3">
              <label htmlFor="files">
                Télécharger des fichiers (télécharger plusieurs images)
              </label>
              <CFormInput
                name="files"
                id="files"
                type="file"
                accept="image/png,image/jpeg"
                // value={files as any}
                multiple
                onChange={onFileUpload}
              />
            </div>
          )}

          {fileFields.length > 0 ? (
            <>
              <div className="mt-4">
                <hr />
                {fileFields.map((field, index) => (
                  <UploadedDocument
                    key={field.id}
                    field={field}
                    index={index}
                    control={control}
                    documentsType={documents}
                    onRemove={() => removeFileItemField(index)}
                  />
                ))}

                <div className="mt-3">
                  <button
                    className="btn btn-success shadow-secondary rounded-md p-2 mb-1 text-sm text-white"
                    type="button"
                    onClick={handleSubmit(onDocumentSave)}
                    disabled={uploadFileMutation.isLoading}
                  >
                    {uploadFileMutation.isLoading
                      ? "Enregistrement des documents..."
                      : "Télécharger des documents"}
                  </button>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

type UploadedDocumentProps = {
  field: {
    type: string;
    file: any;
  };
  control: any;
  index: number;
  documentsType: { name: string; label: string }[];
  onRemove: VoidFunction;
};

function UploadedDocument(props: UploadedDocumentProps) {
  const { field, control, index, documentsType, onRemove } = props;

  return (
    <div className="d-flex align-items-center gap-4">
      <div className="mt-3">
        {field.file ? (
          <img
            src={URL.createObjectURL(field?.file)}
            alt=""
            className="rounded-md"
            width={180}
            height={130}
          />
        ) : null}
      </div>
      <div>
        <label htmlFor="doc-type" className="mb-1">
          Type de document:
        </label>
        <Controller
          name={`files.${index}.type`}
          control={control}
          render={({ field }) => (
            <select {...field}>
              <option value="">Type de document</option>
              {documentsType.map(doc => (
                <option value={doc.name}>{doc.label}</option>
              ))}
            </select>
          )}
        />
      </div>
      <button
        className="outline-none border-0 bg-transparent"
        onClick={onRemove}
        type="button"
      >
        <DeleteIcon
          width="20"
          height="20"
          fill={colors.primary}
          className="ms-2"
        />
      </button>
    </div>
  );
}
